// --------------------------------------------------
// Intro 001
// --------------------------------------------------


.intro001 {
  position: relative;
  max-width: 100%;
  width: 100%;
  min-height: 250px;

  @media(max-width: 768px){
    padding: 30px 0 0;
  }

  .intro001-wrapper {
    @extend .site-max-width;
    position: absolute;
    top: -50px;
    left: calc(50%);
    transform: translateX(-50%);
    max-width: 1170px;
    padding: 30px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #E1E1E1;
    box-shadow: 0px 8px 10px #E5E5E5;
    border-radius: 4px;
    background-color: $white;


    @media(max-width: 1100px){
      position: static;
      transform: translate(0, 0);
      max-width: 90%;
      padding: 10px 0;
    }

    @media(max-width: 768px){
      flex-direction: column;
      text-align: center;
    }

    @media(max-width: 450px){
      position: relative;
      left: auto;
      transform: none;
    }
  }

  .block-icon{
    padding: 20px 80px;
    border-right: 2px solid $colorAccent1;

    @media(max-width: 1100px){
      padding: 20px;
    }

    @media(max-width: 768px){
      padding: 0;
      border-right: none;
    }

    img{
      width: 90px;

      @media(max-width: 768px){
        width: 50px;
      }
    }
  }

  .block-content {
    padding: 0 35px;
    margin: 0;

    @media(max-width: 1100px){
      padding: 0 20px;
    }
  }

  .block-text{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 214% ;
    letter-spacing: 0.525px;
    color: #454545;
    // max-width: 780px;

    @media(max-width: 599px){
      font-size: 12px;
    }
  }
}
