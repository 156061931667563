// --------------------------------------------------
// Title
// --------------------------------------------------

b,
strong {
  font-weight: 500;
}

p a {
  color: $colorAccent1;
  text-decoration: underline;
}

.big-title {
  font-family: $fontTitle;
  font-size: 45px;
  line-height: 1;
  font-weight: bold;

  @media (max-width: 767px) {
    font-size: 35px;
  }

  @media(max-width: 500px){
    font-size: 25px;
  }

  strong {
    font-weight: 700;
  }
}

.fonts-loaded .big-title {
  font-family: $fontTitle;
}

.medium-title {
  font-family: $fontTitle;
  font-size: 30px;
  font-weight: bold;
  line-height: 45px;

  // @media (max-width: 1099px) {
  //   font-size: 28px;
  //   line-height: 1.4;
  // }

  @media (max-width: 767px) {
    font-size: 22px;
  }

  @media(max-width: 500px){
    font-size: 20px;
  }

  strong {
    font-weight: 700;
  }
}

.fonts-loaded .medium-title {
  font-family: $fontTitle;
}

.small-title {
  font-family: $fontTitle;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 1.4;
  }

  strong {
    font-weight: 700;
  }
}

.fonts-loaded .small-title {
  font-family: $fontTitle;
}

// --------------------------------------------------
// text-emphasis: dot;
// --------------------------------------------------

.regular-text {
  font-family: $fontText;
  font-size: 16px;
  line-height: 24px;

  // @media only screen and (max-width: 1099px) {
  //   font-size: 14px;
  //   line-height: 1.5;
  // }

  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }

  strong {
    font-weight: 600;
    color: #3F4040;
  }

  a {
    color: $colorAccent3;
    transition: all 0.35s ease;

    &:hover {
      color: $colorAccent1;
    }
  }

  &.is-centered {
    width: 100%;
    text-align: center;
    letter-spacing: 0.035em;
    font-size: 18px;
  }
}

.medium-text {
  font-family: $fontText;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.8;

  @media only screen and (max-width: 1099px) {
    font-size: 16px;
    line-height: 1.5;
  }

  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }

  strong {
    font-weight: 700;
  }
}

.text-input {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.29px;
  line-height: 22px;
  color: $dark;

  @media screen and (min-width: 1440px) {
    font-size: 14px;
    line-height: 24px;
  }
}

.small-text {
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.525px;

  // @media only screen and (max-width: 1099px) {
  //   font-size: 16px;
  //   line-height: 1.5;
  // }

  @media only screen and (max-width: 767px) {
    font-size: 12px;
  }
}

.links {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.29px;
  line-height: 14px;
  color: $colorAccent2;

  @media screen and (min-width: 1440px) {
    font-size: 24px;
    line-height: 16px;
  }
}

.button-text {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.27px;
  line-height: 11px;

  @media screen and (min-width: 1440px) {
    font-size: 13px;
    line-height: 13px;
  }
}

.pagination {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;
  color: $dark;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// --------------------------------------------------
// Extra
// --------------------------------------------------

.light-typo {
  color: #fff;
}

.lightblue-typo {
  color: $colorAccent2;
}

.centered {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.separator-content-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;

  @media only screen and (max-width: 989px) {
    max-width: 700px;
    margin: 0 auto;
  }
}

ul {
  li {
    line-height: 2;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      line-height: 2;
    }
  }
}

.before-line-effect {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 15px;
    bottom: 15px;
    left: -25px;
    width: 6px;
    background: $colorAccent1;
    z-index: 1;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &.is-small {
    &:before {
      width: 4px;
      top: 0;
      bottom: 0;
      left: -30px;
    }
  }
}

.bottom-line-seperator {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: -35px;
    left: 0;
    width: 80px;
    height: 4px;
    background: $colorAccent1;
    z-index: 1;
  }

  &.is-small {
    &:before {
      width: 40px;
      height: 2px;
      bottom: -15px;
    }
  }

  &.line-is-centered {
    &:before {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
