

//Footer
.site-footer {
  background-color: $white;
  padding: 90px 0 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 550px) {
    padding: 40px 0;
  }

  .footer-container {
    padding: 0 20px;

    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 1000px) {
      flex-direction: column;
      margin-bottom: 0;
    }
    .footer-top {
      display: flex;
      align-items: center;

      .footer-logo {
        display: block;
        height: 45px;
        margin-right: 10px;
      }
      .footer-line {
        width: 100%;
        height: 1px;
        background-color: $colorAccent1;
        margin-top: 10px;
      }
    }

    .footer-middle {
      display: flex;
      padding: 15px 0px;
      justify-content: flex-start;
      margin-bottom: 5px;
      margin-top: -5px;

      @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      @media (max-width: 599px) {
        margin-top: 20px;
      }

      .footer-col-container{
        display: flex;
        margin: 0 auto;

        @media(max-width: 767px){
          flex-direction: column;
        }
      }

      .footer-col {
        display: flex;
        margin-right: 80px;
        align-items: flex-start;
        color: $dark;

        @media (max-width: 1024px) {
          margin-right: 0;
          margin-bottom: 40px;
          padding: 0 20px;
          justify-content: center;
          align-items: center;
        }

        @media (max-width: 599px) {
          margin-bottom: 20px;
          text-align: center;
        }

        &:first-child{
          color: $colorAccent1;
        }

        &:last-child {
          margin-right: 0;
        }

        &:nth-child(3){
          .nav-link{
            white-space:normal;
          }
        }

        .footer-col-content {
          display: flex;
          flex-direction: column;

          .nav-link {
            // &:first-child {
            //   // padding-bottom: 5px;
            // }
          }
        }
      }

      img {
        display: block;
        // width: 30px;
        margin-right: 20px;
        // align-self: center;

        &.letter-icon{ width: 32px; }
        &.phone-icon{ width: 24px; }
        &.map-icon{ width: 17px;}
      }

      .nav-link {
        display: block;
        font-family: $fontTitle;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: .5px;
        font-weight: 500;
        // color: $colorAccent1;
        padding: 0;
        transition: color ease-out .2s;
        max-width: 200px;
        white-space: nowrap;

        i {
          // margin-right: 12px;
          font-size: 32px;
          color: $colorAccent1;
        }

        &:hover {
          color: $colorAccent1;
        }
      }

      p.nav-link{
        margin: 0;
      }
    }


    .footer-bottom {
      display: flex;
      font-size: 12px;
      line-height: 21px;
      letter-spacing: .5px;
      color: $colorAccent1;
      font-weight: 500;

      @media (max-width: 1023px) {
        flex-direction: column;
        align-items: center;
      }

      .footer-legal {
        margin: 0 10px 0 0;
        border-right: 1px solid white;
        padding-right: 10px;
        font-size: 8px;

        @media (max-width: 1023px) {
          border-right: none;
          padding-right: 0;
          margin-right: 0;
        }
      }
    }
  }
}
