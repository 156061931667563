%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

/* Loader 2 */
.loader-container {
  position: relative;
  width: 100%;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  margin: 0px auto;
  width: 200px;
}
.loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124px;
  }
}
@keyframes color {
  100%, 0% {
    stroke: #E1E1E1;
  }
  40% {
    stroke: #CCCCCC;
  }
  66% {
    stroke: #AEAEAE;
  }
  80%, 90% {
    stroke: #CCCCCC;
  }
}

// Fade
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;

  @media(max-width: 767px) {
    transition: none;
  }
}
.fade-enter, .fade-leave-to  {
  opacity: 0;
  // transform: translate(0, 25px);
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.fade-box-enter-active {
  transition: all .45s ease;

  @media(max-width: 767px) {
    transition: none;
  }
}
.fade-box-leave-active {
  transition: all .45s ease;

  @media(max-width: 767px) {
    transition: none;
  }
}
.fade-box-enter, .fade-box-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.fade-delay-enter-active{
  transition: opacity .75s, transform .75s;
  transition-delay: .75s;
}
.fade-delay-enter, .fade-delay-leave-to {
  opacity: 0;
  transform: translate(0, 25px);
}

.flickity-prev-next-button {
  background: $colorAccent1;

  &.previous,
  &.next {
    width: 48px;
    height: 48px;

    @media(max-width: 767px) {
      width: 32px;
      height: 32px;
    }

    &:before {
      position: absolute;
      left: 50%;
      top: 50%;
      font-family: 'fontello';
      color: $white;
      font-size: 20px;

      @media(max-width: 767px) {
        font-size: 16px;
      }
    }

    svg {
      display: none;
    }
  }

  &.next {
    right: -20px;

    &:before {
      content: '\e806';
      transform: translate(-35%, -50%);
    }
  }

  &.previous {
    left: -20px;

    &:before {
      content: '\e805';
      transform: translate(-65%, -50%);
    }
  }
}

.flickity-page-dots {
  bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .dot {
    width: 12px;
    height: 12px;
    border: 1px solid #71C0EB;
    background: $white;
    opacity: 1;

    @media(max-width: 767px) {
      width: 10px;
      height: 10px;
    }

    &.is-selected {
      width: 16px;
      height: 16px;
      border: 0;
      background: $colorAccent1;

      @media(max-width: 767px) {
        width: 14px;
        height: 14px;
      }
    }
  }
}
