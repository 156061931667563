.homepage {
  .intro001 {
    min-height: inherit;

    margin-bottom: 100px;

    @media(max-width: 1099px) {
      margin-bottom: 0;
    }

    // @media(max-width: 768px) {
    //   margin-bottom: 50px;
    // }
    //
    // @media(max-width: 599px) {
    //   margin-bottom: 20px;
    // }

    .intro001-wrapper {
      position: relative;
      top: 0;
      left: 0;
      transform: inherit;
      padding: 20px 0;
      border: none;
      box-shadow: inherit;
      border-radius: 0;

      @media(max-width: 767px) {
        padding: 0;
      }

      .block-text  {
        width: 100%;
        max-width: inherit;
        font-style: italic;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        /* or 200% */
        text-align: center;
        letter-spacing: 2.65625px;
        color: #5B5B5B;

        @media(max-width: 767px) {
          width: 100%;
          max-width: 100%;
          padding: 0;

          p {
            width: 100%;
          }
        }
      }
    }
  }
}
