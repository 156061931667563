.blog-listing-wrapper {
  position: static;
  padding: 140px 20px 70px;


  &.grey {
    background: #EBEBEB;

    .big-title span {
      background: #EBEBEB;
    }
  }

  @media(max-width: 900px) {
    padding: 40px 20px;
  }

  @media(max-width: 599px) {
    padding: 40px 0;
  }
}

.blog-listing-container {
  position: static;
  padding: 140px 70px 40px;
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #EFF4F7;

  @media(max-width: 1099px) {
    margin-top: 60px;
    padding: 70px 20px 20px;
  }

  @media(max-width: 599px) {
    margin-top: 0px;
    padding: 40px 20px 20px;
  }

  .blog-title {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 28px;
    margin: 0 0 40px 0 ;
    text-transform: uppercase;
    color: $colorAccent1;

    @media(max-width: 599px) {
      width: 100%;
      text-align: center;
      font-size: 26px;
    }
  }

  .blog-filters {
    display: block;
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */
    text-transform: uppercase;

    color: #004367;
    border-bottom: 2px solid #979797;
  }

  .blog-filters-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 200px;

    @media(max-width: 1440px) {
      justify-content: center;
    }

    @media(max-width: 1099px) {
      margin-bottom: 100px;
    }

    @media(max-width: 768px) {
      margin-bottom: 20px;
    }

    .blog-filter {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
      border-color: $white;
      color: $colorAccent1;
      max-height: 40px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      /* identical to box height, or 200% */

      letter-spacing: 0.618px;
      text-transform: uppercase;

      color: #004367;

      @media(max-width: 1440px) {
        margin-right: 25px;
      }

      &.is-active {
        background-color: $colorAccent1;
        border-color: $colorAccent1;
        color: $white;
      }

    }
  }

  .blog-intro {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;

    color: $colorAccent1;
    max-width: 460px;
    margin: 0 0 70px 0 ;


    @media(max-width: 699px) {
      font-size: 18px;
      text-align: center;
      width: 100%;
    }
  }

  .site-btn {
    margin-top: 40px;

    @media(max-width: 900px) {
      margin-top: 20px;
    }
  }

  &.entry {
    padding-top: 0;
    background-color: transparent;

    .blog-listing-title {
      position: relative;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 167%;

      letter-spacing: 0.474231px;
      text-transform: uppercase;

      color: #004367;

      &:before {
        position: absolute;
        left: 110px;
        top: 50%;
        transform: translate(110px, -50%);
        display: block;
        content: '';
        height: 6px;
        min-width: 1107px;
        width: 100%;
        background-color: $colorAccent1;
        overflow: hidden;
      }
    }
  }
}

.blog-listing {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &.wrap {
    display: block;

    .blog-item {
      max-width: 700px;

      @media(max-width: 1024px) {
        max-width: inherit;
      }
    }

  }

  @media(max-width: 1024px) {
    flex-wrap: wrap;
  }

  .listing-left {
    width: 60%;
    max-width: 700px;
    margin-right: 90px;

    @media(max-width: 1099px) {
      width: 100%;
      margin: 0 auto;
    }

    @media(max-width: 599px) {
      padding: 0;
    }
  }

  .listing-right {
    width: 40%;
    max-width: 350px;

    @media(max-width: 1099px) {
      display: none;
    }

    .listing-right-heading {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;

      letter-spacing: 1.03px;
      text-transform: uppercase;

      color: #3F718C;
      margin: 0;
      padding-bottom: 10px;

      border-bottom: 2px solid $dark;
    }

    .listing-link {
      display: block;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 214%;
      /* or 214% */
      letter-spacing: 0.675px;

      color: $dark;

      margin: 12px 0;
      border-bottom: 2px solid #A4A4A4;

      padding-bottom: 17px;
    }
  }
}

.blog-item {
  position: relative;
  width: 100%;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 30px 0;

  overflow: hidden;

  // max-width: 700px;
  min-height: 440px;

  &:last-child {
    margin-bottom: 0;
  }

  // @media(max-width: 1280px){
  //   width: calc(100% / 2);
  // }

  @media(max-width: 650px) {
    padding: 0;
  }

  &:nth-child(3n+3) {
    margin-right: 0;
  }

  @media(max-width: 1024px) {
    width: 100%;

    &:nth-child(3n+3) {
      margin-right: 20px;
    }

    &:nth-child(2n+2) {
      margin-right: 0;
    }
  }

  @media(max-width: 768px) {
    width: 100%;
    margin-right: 0;
    flex-direction: column;
  }

  .blog-card-image {
    display: block;
    width: 100%;
    position: relative;

    @media(max-width: 1099px) {
      margin-bottom: 40px;
    }

    @media(max-width: 599px) {
      margin-bottom: 0px;
    }

    &:before {
      content: ""; // ::before and ::after both require content
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(180deg, transparent, rgba($colorAccent1, .8));
      opacity: .7;
      z-index: 2;
    }

    img{
      display: block;
      width: 100%;
      filter: grayscale(1);
      z-index: 1;
      margin: 0;
    }
  }

}

.blog-item-infos {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // width: 100%;
  width: 55%;
  min-height: 300px;
  flex-grow: 1;
  padding: 0 30px;
  border-top: 1px solid white;
  color: white;
  font-family: $fontTitle;
  text-align: left;

  @media(max-width: 599px) {
    padding: 20px 10px;
    width: 100%;
    min-height: 0;
  }
}

.blog-item-cat {
  position: absolute;
  left: 30px;
  top: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.721px;
  text-transform: uppercase;

  color: $dark;
  margin-top: 0;
  margin-bottom: 40px;

  @media(max-width: 768px) {
    position: inherit;
  }

  @media(max-width: 599px) {
    margin-bottom: 10px;
    font-size: 12px;
  }
}

.blog-item-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: $dark;
  margin-top: 0;
  margin-bottom: 0;
}

.blog-item-separator {
  display: block;
  width: 70px;
  height: 6px;
  background-color: $colorAccent1;
  margin-bottom: 20px;
}

.blog-item-link {
  position: absolute;
  left: 30px;
  bottom: 35px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height, or 120% */
  letter-spacing: 0.13px;
  text-transform: uppercase;

  color: #004367;
  margin: 0;

  @media(max-width: 768px) {
    position: inherit;
  }
}


.blog-item-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.327273px;

  color: #004367;
  margin-top: 0;
  margin-bottom: 40px;

  @media(max-width: 599px) {
    margin-bottom: 10px;
    font-size: 20px;
  }

}

.block-custom-slider002{
  height: 60vh;
  position: static;

  @media(max-width: 768px) {
    height: 100vh;
  }
  //
  // @media(max-width: 599px) {
  //   height: 1040px;
  // }

  .slider002-container {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: -50px;
    margin-bottom: -50px;

    @media(max-width: 599px) {
      position: inherit;
    }


  }
}



//problematic index .problematic-scope height 90%; .block-custom-slider001 {
                                      // margin-bottom: 80px; (ipad)

//blog entry content-right display none + la vidéo

//page contact     padding: 55px 0; sur block texts=
