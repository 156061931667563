.team .main {
  @extend .site-max-width;
}

.teams-container {
  background: #EFF4F7;
}

.team-vue-scope,
.team-other-categories {
  padding-left: 75px;
  padding-right: 75px;
  transform: translateY(-120px);

  @media(max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.team-heading {
  padding-left: 75px;
  padding-right: 75px;

  @media(max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.team-divider {
  width: 100%;
  height: 2px;
  margin-top: 90px;
  margin-bottom: 30px;
  background: #979797;

  @media screen and (max-width: 767px) {
    margin-top: 60px;
    margin-bottom: 20px;
  }
}

.team-other-categories-text {
  width: 100%;
  max-width: 765px;
}

.teams-spacer {
  @media screen and (max-width: 600px) {
    margin-top: -80px;
  }
}

.team-infos {
  flex: 1;
}

.team {
  padding-top: calc(125px + 100px);
  color: $colorAccent1;

  @media(max-width: 767px) {
    padding-top: 100px;
  }

  .team-category-heading {
    position: relative;
    margin-bottom: 40px;
    font-size: 18px;

    @media(max-width: 600px) {
      font-size: 22px;
      line-height: 31px;
    }
  }

  .team-text {
    width: 100%;
    max-width: 775px;
    margin-top: 30px;
    margin-bottom: 300px;
    padding-left: 75px;
    padding-right: 75px;
    font-family: $fontText;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: $colorAccent1;

    @media(max-width: 767px) {
      margin-top: 15px;
      margin-bottom: 210px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .team-category-text {
    color: $dark;
    margin-bottom: 50px;
  }

  .team-entry-container {
    position: relative;
    // margin-bottom: 85px;
    display: flex;
    //
    flex-wrap: wrap;

    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    padding-bottom: 45px;

    @media(max-width: 767px) {
      margin-bottom: 30px;
    }

    @media(max-width: 600px) {
      flex-wrap: wrap;
      padding-bottom: 20px;
    }

    &.fondateurs-et-propriétaires .team-entry {
      justify-content: space-between;
      max-width: calc(100% / 2 - 20px);
      cursor: pointer;

      &:first-child {
        margin-right: 20px;
      }

      @media(max-width: 480px) {
        max-width: 100%;

        &:first-child {
          margin-right: 0;
        }
      }

      img {
        width: 100%;
      }
    }

    // &.col-3{
    //   // flex-wrap: wrap;
    //
    //   .team-entry{
    //     width: calc((100% / 3) - 20px);
    //     margin-right: 20px;
    //
    //     @media(max-width: 600px){
    //       width: calc((100% / 2) - 20px);
    //     }
    //
    //     @media(max-width: 400px){
    //       width: 100%;
    //       margin: 0;
    //     }
    //
    //     &:last-child{
    //       margin: 0;
    //     }
    //   }
    // }

    & {
      .team-name {
        // min-height: 90px;
        font-size: 20px;
        line-height: 35px;
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 1;

        @media(max-width: 600px) {
          min-height: 0;
        }

        @media(max-width: 450px) {
          font-size: 18px;
        }
      }

      .team-job-title {
        font-size: 18px;
        font-weight: 400;
        overflow-wrap: anywhere;
        margin: 0 0 25px 0;

        @media(max-width: 600px) {
          min-height: 0;
          font-size: 16px;
        }
      }

      img {
        width: 100%;
      }
    }

    &:not(.fondateurs-et-propriétaires) {
      .team-entry {
        width: calc((100% / 3) - 20px);
        margin-right: 20px;
        // margin-right: 100px;
        margin-bottom: 30px;
        // color: rgba($colorAccent1, .5);
        transition: height .75s, color .75s;
        cursor: pointer;

        @media(max-width: 768px) {
          width: calc((100% / 2) - 20px);
          margin-bottom: 25px;
        }

        @media(max-width: 600px) {
          width: 100%;
          margin: 0 0 25px 0;
        }

        &:last-child {
          margin: 0 0 25px 0;
        }

        &.is-expended {
          color: $colorAccent1;
        }

        &:hover {
          cursor: pointer;
          color: $colorAccent1;
        }


      }

    }
  }

  .team-infos-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;

    .team-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      margin-top: 10px;
      border-radius: 50%;
      background-color: $white;
      transition: all .3s ease-in-out;

      svg {
        transition: all .3s ease-in-out;

        path {
          fill: $colorAccent1;
          transition: all .3s ease-in-out;
        }
      }

      &.is-active {
        background-color: $colorAccent1;

        svg {
          transform: rotate(180deg);

          path {
            fill: $white;
          }
        }
      }
    }
  }


  .team-entry-infos {
    // display: none;
    max-width: 880px;
    margin: 0 auto;
    padding: 30px 60px;
    border-left: 5px solid $colorAccent1;
    background-color: $white;
    box-shadow: 0px 8px 10px #E5E5E5;
    color: $colorAccent1;

    @media(max-width: 767px) {
      padding: 10px 30px;
    }

    &.is-active {
      display: block;
    }
  }

  // .inline-expender-block {
  //   position: absolute;
  //   top: auto;
  //   left: 0;
  //   right: 0;
  //   z-index: 5;
  //   padding: 20px;
  //   background: #f1f1f1;
  //   border: 1px solid rgba(0, 0, 0, .08);
  // }

  .entry-description-container {
    z-index: 5;
    margin-bottom: 130px;

    @media(max-width: 767px) {
      margin-bottom: 60px;
    }
  }
}
