.problematics-entry{
  .intro001{
    min-height: 200px;
  }
}

.entry-nav{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media(max-width: 599px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .entry-nav-links{
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0.13px;
    text-transform: uppercase;
    font-weight: bold;
    color: $colorAccent1;

    @media(max-width: 599px){
      display: block;
      margin-bottom: 15px;
    }

    &.no-more-links{
      color: rgba($colorAccent2, .5);

      @media(max-width: 599px){
        display: none;
      }

      &:hover{
        cursor: not-allowed;
      }
    }

    &.prev-link:before{
      content: "\E827";
      position: relative;
      margin-right: 10px;
      font-family: fontello;
      color: inherit;
      font-size: 10px;
      font-weight: bold;
    }

    &.next-link:after{
      content: "\E826";
      position: relative;
      margin-left: 10px;
      font-family: fontello;
      color: inherit;
      font-size: 10px;
      font-weight: bold;
    }
  }
}

.blog001{
  position: relative;
  padding: 0 75px;
  max-width: calc(1140px + 75px * 2);
  color: $colorAccent1;

  @media only screen and (max-width: 1250px) {
    // padding: 75px 45px;
    padding-left: 45px;
    padding-right: 45px;
  }

  @media only screen and (max-width: 1099px) {
    // padding: 75px 25px;
    padding-left: 25px;
    padding-right: 25px;
  }

  @media only screen and (max-width: 767px) {
    // padding: 25px 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .blog001-wrapper{
    position: relative;
    padding: 70px 0;
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media(max-width: 1250px){
      flex-direction: column;
      padding: 0;
      margin-bottom: 0;
    }

    @media(max-width: 599px){
      margin-bottom: 50px;
    }

    &:nth-child(even){
      flex-direction: row-reverse;

      @media(max-width: 1250px){
        flex-direction: column;
      }

      .text-wrapper{
        right: auto;
        left: 0;
      }
    }
  }

  .image-wrapper{
    position: relative;
    max-width: 560px;
    z-index: 2;

    @media(max-width: 1250px){
      width: 100%;
      max-width: none;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(180deg, transparent, rgba(0, 67, 103, 0.8));
      opacity: .7;
      z-index: 2;
    }

    img{
      display: block;
      width: 100%;
      // height:100%;
      filter: grayscale(1);
    }
  }

  .text-wrapper{
    position: absolute;
    right: 0;
    top: 35px;
    max-width: 600px;
    padding: 70px;
    background-color: #EFF4F7;
    // transform: translate(0, -50%);

    @media(max-width: 1250px){
      position: relative;
      top: -90px;
      width: 100%;
      max-width: none;
      z-index: 0;
    }

    @media(max-width: 767px){
      padding: 30px 20px;
    }

    @media(max-width: 599px){
      top: 0;
    }

    .blog001-heading{
      position: relative;
      padding-bottom: 30px;
      font-size: 30px;
      line-height: 1.3;

      @media(max-width: 1159px){
        margin-top: 35px;
      }

      @media(max-width: 767px){
        padding-bottom: 0;
        margin: 0;
        font-size: 25px;
      }

      @media(max-width: 599px){
        font-size: 20px;
      }

      &::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: -5px;
        display: block;
        width: 70px;
        height: 5px;
        background-color: $colorAccent1;
        font-weight: bold;

        @media(max-width: 767px){
          display: none;
        }
      }
    }

    .blog001-text{
      margin: 30px 0 40px;
      line-height: 1.5;
      color: $colorTextDark;

      @media(max-width: 767px){
        margin: 10px 0 20px;
      }

      @media(max-width: 599px){
        font-size: 13px;
      }
    }
  }

}
