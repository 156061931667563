.faq {

  .accordion-wrapper {
    @extend .site-max-width;

    max-width: 1024px;

    padding: 45px 20px;

    @media(max-width: 1024px) {
      padding: 45px 20px;
    }

    @media(max-width: 767px) {
      padding: 35px 20px;
    }
    .accordion-row {
      color: $white;

      &:hover{
        cursor: pointer;
      }

      .accordion-title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        /* identical to box height, or 133% */

        color: $dark;
        padding: 20px;
        // background-color: rgba(43, 43, 43, 0.2);
        border-bottom: 2px solid #979797;

        &.is-active {
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          /* identical to box height, or 133% */

          color: #004367;
        }

        i {
          margin-left: auto;

          &.close {
            transform: rotate(90deg);
          }
        }

        p {
          margin: 0;
        }

        span {
          margin-right: 20px;
        }

        span,
        i {
          color: $colorAccent1;
        }


      }

      .accordion-text {
        display: none;
        font-weight: normal;
        font-size: 14px;
        line-height: 23px;
        // background-color: rgba(43, 43, 43, 0.4);
        padding: 40px 50px;
        flex-direction: column;

        color: $colorAccent1;

        @media(max-width: 599px) {
          padding: 20px;
        }


        p {
          // width: 48%;
          // max-width: 350px;
          margin-right: 20px;
          color: $dark;
          font-size: 18px;
          line-height: 1.4;

          &:nth-child(2n+2) {
            margin-right: 0;
          }

          @media(max-width: 767px) {
            width: 100%;
            font-size: 16px;
          }
        }

        &.is-active {
          display: flex;

          @media(max-width: 767px) {
            flex-direction: column;
          }
        }
      }
    }
  }
}
