.page-subnav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 60px;
  margin-bottom: 80px;
  height: 70px;
  // width: calc(100% - 70px);
  width: 100%;
  background-color: $dark;

  @media (max-width: 1023px) {
    padding: 28px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  &:not(.share) {
    @media (max-width: 767px) {
      display: none;
    }
  }

  &.for-stay {
    @media (max-width: 767px) {
      height: auto;
      padding: 10px 0;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 40px;

      &.share {
        flex-direction: row;
        padding: 10px 20px;
        margin-bottom: 20px;

        .nav-link {
          padding-left: 0;
        }
      }

      .nav-link {
        line-height: 30px;
        margin-left: 0;
        padding-left: 20px;

        &.is-active {
          -webkit-box-shadow: inset 5px 0px 0px 0px $colorAccent1;
          -moz-box-shadow: inset 5px 0px 0px 0px $colorAccent1;
          box-shadow: inset 5px 0px 0px 0px $colorAccent1;
        }
      }
    }
  }

  &.share {
    margin-bottom: 0;
  }

  .nav-link {
    font-family: $fontTitle;
    font-weight: 600;
    font-size: 14px;
    line-height: 70px;
    letter-spacing: .5px;
    text-transform: uppercase;
    color: white;
    margin-left: 50px;

    @media (max-width: 1400px) {
      margin-left: 20px;
    }

    &.is-active {
      -webkit-box-shadow: inset 0px -5px 0px 0px $colorAccent1;
      -moz-box-shadow: inset 0px -5px 0px 0px $colorAccent1;
      box-shadow: inset 0px -5px 0px 0px $colorAccent1;
    }

    &.icon {
      width: 20px;
      height: 20px;
      margin-left: 20px;
      font-size: 20px;
      transition: color ease-out .2s;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;

      &.facebook {
        background-image: url('/img/site/icon-facebook.svg');
      }

      &.linkedin {
        background-image: url('/img/site/icon-linkedin.svg');
      }

      &.twitter {
        background-image: url('/img/site/icon-twitter.svg');
      }

      &:hover {
        color: $colorAccent1;
      }
    }
  }
}

.blog-content-wrapper {
  width: 100%;
  max-width: 1440px;
  padding: 150px 70px 20px 100px;
  margin: 0 auto;
  font-family: $fontTitle;

  @media(max-width: 900px) {
    padding: 40px 20px;
  }

  @media(max-width: 599px) {
    padding: 115px 20px;
    padding-bottom: 25px;
  }

  .blog-heading {
    position: relative;
    padding-bottom: 24px;
    margin-bottom: 35px;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
    color: $colorAccent1;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -20px;
      display: block;
      height: 6px;
      width: 130px;
      background-color: $colorAccent1;
    }
  }

  .blog-content-col {
    display: flex;
    width: 100%;

    .content-right {
      width: 30%;

      @media(max-width: 768px) {
        display: none;
      }

      .listing-right-heading {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;

        letter-spacing: 1.03px;
        text-transform: uppercase;

        color: #3F718C;
        margin: 0;
        padding-bottom: 10px;

        border-bottom: 2px solid $dark;
      }

      .listing-link {
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        /* or 214% */
        letter-spacing: 0.675px;

        color: $dark;

        margin: 10px 0;
        border-bottom: 2px solid #A4A4A4;
      }

      strong {
        font-weight: 900;
      }
    }

    .content-left {
      width: 70%;
      margin-right: 90px;

      @media(max-width: 768px) {
        width: 100%;
        margin-right: 0;
      }

      .blog-content {

        figure {
          margin: 0;
          width: 100%;

          img {
            width: 100% !important;
            height: auto !important;

            float: left;
            overflow: hidden;
            margin: 0 20px 10px 0;

          }

          iframe {
            width: 100%;
          }

          @media(max-width: 900px) {
            width: 100%;
            margin: 0 0 20px 0;

            img {
              display: block;
              width: 100% !important;
            }
          }
        }

        a {
          color: $colorAccent1;
        }

        p, ol > li {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 1.6;

          color: $dark;
          margin: 0 0 30px;

          @media (max-width: 1400px) {
            font-size: 16px;
          }
        }

        ol > li {
          margin: 0;
        }

        strong {
          font-family: $fontText;
          font-weight: 700;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 22px;
          line-height: 28px;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-weight: 600;
          margin: 0 0 20px;
          color: $colorAccent1;

          @media(max-width: 900px) {
            font-size: 18px;
            line-height: 22px;
            letter-spacing: .5px;
          }
        }

        ul {
          list-style: none;
          margin: 0 0 30px;
          padding: 0;

          li {
            display: flex;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: .5px;
            color: #757C8B;
            font-weight: 400;

            &:before {
              font-family: "fontello";
              font-style: normal;
              font-weight: normal;
              speak: none;
              display: inline-block;
              text-decoration: inherit;
              width: 1em;
              text-align: center;
              /* opacity: .8; */
              font-variant: normal;
              text-transform: none;
              content: "\E82B";
              margin-right: 20px;
              color: $colorAccent1;

              @media(max-width: 900px) {
                margin-right: 10px;
              }
            }
          }
        }

        .video-container {
          position: relative;
          padding-bottom: 56.25%;
          /* 16:9 */
          padding-top: 25px;
          height: 0;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
          }
        }
      }

      .blog-intro {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        margin-bottom: 30px;
        padding-bottom: 25px;

        &:after {
          position: absolute;
          content: '';
          width: 130px;
          height: 6px;
          background-color: $colorAccent1;
          bottom: 0;
          left: 0;
          transform: translateX(-50%);
        }

        .blog-intro-title {
          font-style: normal;
          font-weight: bold;
          font-size: 32px;
          line-height: 42px;


          color: $colorAccent1;

          margin: 0;

          @media(max-width: 900px) {
            font-size: 22px;
            line-height: 26px;
            letter-spacing: .5px;
          }
        }

        .blog-intro-subtitle {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: .6px;
          color: #757C8B;
          font-weight: 400;
          margin: 20px 0 0;
          text-align: center;
        }
      }

      .blog-cta {
        display: flex;
        align-items: center;
        border-top: 2px solid #EBECEE;
        border-bottom: 2px solid #EBECEE;
        margin: 60px 0 60px;
        padding: 50px 0;

        @media(max-width: 700px) {
          flex-direction: column;
        }

        .blog-cta-title {
          font-size: 24px;
          line-height: 28px;
          letter-spacing: 1px;
          color: #3F3F3F;
          font-weight: 600;
          margin: 0 60px 0 0;
          text-transform: uppercase;
          width: 60%;

          @media(max-width: 700px) {
            width: 100%;
            margin: 0;
            font-size: 18px;
            line-height: 22px;
          }
        }

        .site-btn {
          width: 40%;

          @media(max-width: 700px) {
            margin-top: 20px;
            width: 100%;
          }

          &:hover {
            background-color: #fff;
            color: $colorAccent1;
          }
        }
      }

      .blog-quote {
        display: flex;
        flex-direction: column;
        // align-items: center;
        margin: 0 0 60px;

        // &:before {
        //   font-family: "fontello";
        //   font-style: normal;
        //   font-weight: normal;
        //   speak: none;
        //   display: inline-block;
        //   text-decoration: inherit;
        //   width: 1em;
        //   text-align: center;
        //   /* opacity: .8; */
        //   font-variant: normal;
        //   text-transform: none;
        //   content: "\E82C";
        //   color: #EBEBEB;
        //   font-size: 26px;
        //   line-height: 26px;
        //   margin-bottom: 25px;
        // }

        .blog-quote-title {
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 146%;
          /* or 146% */

          color: #3F718C;

          border-top: 2px solid #3F718C;
          border-bottom: 2px solid #3F718C;

          padding: 25px 0;

          @media(max-width: 900px) {
            font-size: 18px;
          }
        }

        .blog-quote-author {
          font-size: 14px;
          line-height: 19px;
          letter-spacing: .88px;
          font-weight: 600;
          margin: 0;
          color: $colorAccent1;
          // text-align: center;
        }

        .blog-quote-subtitle {
          font-family: $fontTextLight;
          font-size: 12px;
          line-height: 25px;
          letter-spacing: .2px;
          font-weight: 400;
          margin: 5px 0 0;
          color: $colorAccent1;
          // text-align: center;
        }
      }

      .blog-author {
        margin: 0 0 60px;

        .big-title {
          margin-bottom: 60px;

          span {
            background-color: white;
          }
        }

        .blog-author-infos {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
        }

        .blog-author-avatar {
          width: 60px;
          margin-right: 20px;

          @media(max-width: 900px) {
            width: 40px;
          }
        }

        .blog-author-bio {
          font-family: $fontTextLight;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0;
          color: #8F9DAE;
          font-weight: 400;
          margin: 0;
        }

        .blog-author-name {
          font-size: 18px;
          line-height: 22px;
          font-weight: 700;
          letter-spacing: .9px;
          margin: 0;
          text-transform: uppercase;
        }

        .blog-author-job {
          font-family: $fontTextLight;
          font-size: 14px;
          line-height: 14px;
          letter-spacing: 0;
          margin: 5px 0 0;
          font-weight: 400;
          color: $colorAccent1;
        }
      }
    }
  }
}
