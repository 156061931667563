// --------------------------------------------------
// Btns components
// --------------------------------------------------
.site-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  border: 1px solid $colorAccent2;
  background: $colorAccent1;
  color: #fff;
  font-family: $fontTextLight;
  font-size: 16px;
  line-height: 1;
  letter-spacing: -.025em;
  text-align: center;
  cursor: pointer;
  transition: .3s;
  transition-duration: .3s;
  -webkit-appearance: none;
  border-radius: 20px;
  min-width: 175px;
  max-height: 40px;

  &:hover {
    opacity: .75;
  }

  &.is-alternate {
    padding: 0;
    margin: 0;
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
    letter-spacing: 0.13px;
    text-transform: uppercase;
    color: #004367;
    background-color: transparent;
    border: none;
    text-align: left;

    &::after{
      content: "\E826";
      position: relative;
      margin-left: 15px;
      font-family: fontello;

    }
  }

  &.is-inline {
    display: inline-block;
    line-height: 10px;
  }

  &.is-bold {
    font-weight: 700;
  }

  &.is-outlined {
    padding: 15px 25px;
    border: 1px solid $colorAccent1;
    background: transparent;
    color: $colorAccent1;
    font-family: $fontTitleSerif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;

    &:hover {
      border-color: $colorAccent3;
      color: $colorAccent3;
      opacity: 1;
    }

    &.reverted-hover {
      &:hover {
        border-color: $dark;
        color: $dark;
        opacity: 1;
      }
    }
  }

  &.is-transparent {
    color: $colorAccent1;
    background-color: rgba($white, .6);
  }

  &.is-white {
    color: $colorAccent1;
    background-color: $white;
    transition: all 0.3s ease;

    &:hover {
      background-color: rgba($white, .6);
    }
  }

  &.is-light {
    color: $white;
    border: 1px solid $white;
    background-color: transparent;

    &:hover {
      background-color: rgba($white, .3);
    }
  }

  &.is-bigger {
    min-width: 200px;
    padding: 15px 25px;
    font-size: 18px;
  }

  &.is-alternate {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.13px;
    text-transform: uppercase;
    color: #004367;
    background-color: none;
    justify-content: flex-start;
  }
}

.site-link-btn {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: $colorAccent1;
}
