// --------------------------------------------------
// Hero
// --------------------------------------------------

.site-hero-ab {
  position: relative;
  height: 100vh;
  min-height: 800px;
  padding-top: 100px;
  padding-bottom: 100px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  overflow: hidden;

  @media only screen and (max-width: 1349px) {
    padding-top: 232px;
    padding-bottom: 75px;
  }

  @media only screen and (max-width: 1099px) {
    padding-top: 228px;
    padding-bottom: 55px;
  }

  @media only screen and (max-width: 767px) {
    background-position: center center;
    padding-top: 188px;
    padding-bottom: 45px;
  }

  @media only screen and (max-width: 699px) {
    padding-top: 158px;
    padding-bottom: 45px;
  }

  .has-overlay {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 5;
    }

    &.dark:before {
      background: rgba($dark, .9);
    }

    &.light:before {
      background: rgba($light, .9);
    }

    &.accent1:before {
      background: rgba($colorAccent1, .9);
    }

    &.accent2:before {
      background: rgba($colorAccent2, .9);
    }

    &.gradient:before {
      background: rgba(1, 68, 105, 0.5);
    }
  }

  .hero-slider {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .flickity-viewport {
      width: 100%;
      height: 100%;
    }

    .hero-slide {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      // background: linear-gradient(272.74deg, rgba(0, 0, 0, 0.72) 2.26%, rgba(0, 0, 0, 0) 98.79%);
    }
  }

  .hero-video-wrapper {
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    overflow: hidden;
    background-size: cover;
    background-color: rgb(0, 0, 0);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: none;

    .hero-video {
      display: block;
      margin: auto;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: visible;
      opacity: 1;
      width: 1682px;
      height: auto;
    }
  }

  .hero-wrapper {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 100px;

    &.align-left {
      align-items: flex-start;
      text-align: left;
    }

    &.align-center {
      align-items: center;
      text-align: center;
    }

    &.align-right {
      align-items: flex-end;
      text-align: right;
    }

    .hero-heading {
      max-width: 710px;
      margin-top: 0;
      margin-bottom: 30px;
      font-weight: normal;
      font-size: 50px;
      line-height: 1.4;
      color: $white;
      // text-shadow: 2px 2px 12px rgba(255, 255, 255, 0.5);

      @media only screen and (max-width: 1349px) {
        font-size: 55px;
      }

      @media only screen and (max-width: 1099px) {
        margin-bottom: 50px;
        font-size: 35px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 30px;
        line-height: 1.1;
        margin-bottom: 25px;
      }
    }

    .hero-text {
      max-width: 710px;
      margin-top: 0;
      margin-bottom: 46px;
      font-style: normal;
      font-family: $fontTitleSpecial;
      font-size: 48px;
      line-height: 60px;
      letter-spacing: 0;
      color: $white;

      p {
        margin: 0;
        font-weight: normal;

        strong {
          font-weight: bold;
        }
      }

      @media (max-width: 767px) {
        font-size: 32px;
        line-height: 1.2;
        margin-bottom: 25px;
      }
    }

    .hero-floating-block {
      display: block;
      max-width: 456px;
      font-family: Verdana;
      font-style: normal;
      font-weight: normal;
      font-family: $fontTextSpecial;
      font-size: 16px;
      line-height: 180%;
      color: $white;
      margin-top: 0;
      margin-bottom: 40px;

      p {
        margin: 0;
      }

      @media (max-width: 767px) {
        line-height: 1.2;
      }
    }

    .hero-btn {
      margin: 0;
      padding: 15px;

      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      border: none;

      color: #014469;

      font-family: $fontTextSpecial;
    }

    .site-link-btn {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .mouse {
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
    z-index: 10;

    .mouse-icon {
      width: 25px;
      height: 45px;
      border: 2px solid white;
      border-radius: 15px;
      cursor: pointer;
      position: relative;
      text-align: center;
    }

    .mouse-wheel {
      height: 6px;
      margin: 2px auto 0;
      display: block;
      width: 3px;
      background-color: white;
      border-radius: 50%;
      -webkit-animation: 1.6s ease infinite wheel-up-down;
      -moz-animation: 1.6s ease infinite wheel-up-down;
      animation: 1.6s ease infinite wheel-up-down;
    }

    @-webkit-keyframes wheel-up-down {
      0% {
        margin-top: 2px;
        opacity: 0;
      }

      30% {
        opacity: 1;
      }

      100% {
        margin-top: 20px;
        opacity: 0;
      }
    }

    @-moz-keyframes wheel-up-down {
      0% {
        margin-top: 2px;
        opacity: 0;
      }

      30% {
        opacity: 1;
      }

      100% {
        margin-top: 20px;
        opacity: 0;
      }
    }

    @keyframes wheel-up-down {
      0% {
        margin-top: 2px;
        opacity: 0;
      }

      30% {
        opacity: 1;
      }

      100% {
        margin-top: 20px;
        opacity: 0;
      }
    }
  }
}

