// --------------------------------------------------
// Block Slider003
// --------------------------------------------------

.slider003 {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;

  @media (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .slider003-heading {
    @extend .big-title;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
    color: $dark;
  }

  .slider003-text {
    @extend .regular-text;
    width: 100%;
    max-width: 775px;
    padding: 0 20px;
    text-align: center;
    margin: 0 auto 60px;

    @media (max-width: 1070px) {
      width: 60%;
    }

    @media (max-width: 599px) {
      width: 100%;
    }
  }

  .slider003-slider {
    // @extend .site-max-width;
    text-align: center;

    // @media (max-width: 1249px) {
    //   padding: 0 65px 50px;
    // }
    //
    // @media (max-width: 1024px) {
    //   padding: 0 25px 50px;
    // }
    //
    // @media (max-width: 899px) {
    //   padding: 0 0 50px;
    //   margin: -20px auto 60px;
    // }

    .blog-item {
      margin-right: 65px;
      box-shadow: 0px 1px 6px rgba(#E5E5E5, .3);
    }

    .flickity-viewport {
      overflow: hidden;
      min-height: 410px;
      padding: 20px 0;
      margin-bottom: 50px;
    }

    // .image, img {
    //   height: 50px;
    // }

    // .value-title {
    //   @extend .title4;
    //   font-size: 16px;
    //   width: 100%;
    //   max-width: 350px;
    //   margin: 20px auto;
    // }

    // .value-desc {
    //   @extend .regular-text;
    //   width: 100%;
    //   max-width: 350px;
    //   margin: 0 auto;
    //   font-size: 14px;
    //   line-height: 18px;
    //
    //   @media all and (max-width: 1024px) {
    //     font-size: 12px;
    //     line-height: 16px;
    //     max-width: 100%;
    //   }
    // }


    // .value-card {
    //   width: calc(100% / 3);
    //   min-height: 370px;
    //   padding: 50px 20px;
    //   border: 1px solid rgba(#90A0B0, .4);
    //   transform: scale(.9);
    //   transition: transform ease-out .2s, box-shadow ease-out .2s;
    //   background-color: white;
    //   box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.1);
    //
    //   &:before {
    //     content: '';
    //     position: absolute;
    //     bottom: 0;
    //     left: -1px;
    //     width: calc(100% + 2px);
    //     height: 3px;
    //     background-color: transparent;
    //   }
    //
    //   @media all and (max-width: 1099px) {
    //     padding: 60px 20px;
    //   }
    //
    //   @media all and (max-width: 1024px) {
    //     padding: 50px 20px;
    //   }
    //
    //   @media all and (max-width: 899px) {
    //     width: 40%;
    //   }
    //
    //   @media all and (max-width: 767px) {
    //     width: 50%;
    //   }
    //
    //   @media all and (max-width: 699px) {
    //     width: 60%;
    //   }
    //
    //   @media all and (max-width: 499px) {
    //     width: 70%;
    //   }
    //
    //   &.is-selected {
    //     position: relative;
    //     z-index: 2;
    //     transform: scale(1.05);
    //     border-bottom: 0;
    //
    //     &:before {
    //       background-color: $colorAccent1;
    //     }
    //   }
    //
    //   // Options
    //   &.grey {
    //     background-color: $grey;
    //   }
    //   &.dark {
    //     background-color: $dark;
    //
    //     .value-title {
    //       color: white;
    //     }
    //   }
    //
    //   &.left {
    //     .value-title, .value-desc {
    //       text-align: left;
    //     }
    //   }
    //   &.right {
    //     .value-title, .value-desc {
    //       text-align: right;
    //     }
    //   }
    // }
  }

  .flickity-prev-next-button {
    width: 50px;
    height: 50px;
    transition: all ease-out .2s;
    cursor: pointer;
    z-index: 1;

    &:hover:not(:disabled) {
      transform: translateY(-50%) scale(1.2);
      opacity: 1;
      box-shadow: 0 0 20px 0 rgba($dark, .5);
    }

    &.previous {
      left: 20px;
    }
    &.next {
      right: 20px;
    }

    &:disabled {
      opacity: .5;
    }
  }

  .flickity-page-dots {
    left: 0;
    bottom: 0;

    .dot {
      background-color: rgba($colorAccent1,.3);
      transition: background-color ease-out .2s;

      &.is-selected {
        background-color: $colorAccent1;
      }
    }
  }

  // Options
  &.grey {
    background-color: $grey;
  }
  &.dark {
    background-color: $dark;

    .slider003-heading {
      color: white;
    }
  }
}
