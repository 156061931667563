// --------------------------------------------------
// Header
// --------------------------------------------------

.main-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  z-index: 50;
  background: linear-gradient(270deg, #67869F 21.08%, #004367 83.47%);

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    // width: 100%;
    -webkit-transition: -webkit-transform 0.7s .3s ease;
    transition: transform 0.7s .3s ease, background-color .5s ease;
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    -webkit-backface-visibility: hidden;
    box-shadow: 0 1px 5px 0px rgba(0, 0, 0, .15);

    background-color: $white;

    &.hide-nav-bar {
      -webkit-transform: translate(0, -100%);
      -ms-transform: translate(0, -100%);
      transform: translate(0, -100%);
      -webkit-backface-visibility: hidden;
    }

    .header-subnav {
      background-color: $white !important;
      box-shadow: 0 5px 5px 0px rgba(0, 0, 0, .15);
    }

    .header-container {
      .header-top {
        background: linear-gradient(270deg, #67869F 21.08%, #004367 83.47%);
      }
    }
  }

  .header-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .header-top {
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      background: transparent;
      color: $white;

      padding: 8px 20px;

      @media (max-width: 1024px) {
        display: none;
      }

      .alert-header-top {
        display: flex;
        align-items: center;

        .popup-alert-picto {
          display: block;
          width: 100%;
          max-width: 12px;
        }

        .popup-banner-title {
          font-family: $fontTitleSpecial;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 16px;
          margin: 0 15px;

          span {
            font-weight: normal;
          }
        }

        .popup-alert-btn {
          font-style: normal;
          font-weight: normal;
          font-size: 8px;
          line-height: 16px;
          padding: 2px 4px;
        }
      }

      .header-phone {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 1px;
        font-weight: 600;
        color: #fff;
        margin: 0 20px 0 0;
        color: $white;

        a {
          color: inherit;
          text-decoration: none;
          transition: all 0.35s ease;
          color: $white;

          &:hover {
            // color: $colorAccent1;
            text-decoration: underline;
          }
        }

        @media (max-width: 1400px) {
          font-size: 14px;
        }
      }
    }

    .header-bottom {
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      background: rgba($white, .75);
      padding: 15px 35px;

      @media (max-width: 1024px) {
        justify-content: flex-end;
        padding-left: 20px;
        padding-right: 20px;
      }

      @media (max-width: 699px) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .mobile-menu-trigger {
    display: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: url('/img/site/icon-burger.svg') no-repeat center center / contain;

    @media (max-width: 1024px) {
      display: block;
    }
  }

  .header-logo {
    margin-right: 80px;

    @media (max-width: 1024px) {
      margin-right: auto;
    }

    img {
      display: block;
      height: 70px;
      max-width: 250px;

      @media (max-width: 767px) {
        max-width: 130px;
      }
    }
  }

  .header-nav {
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;


    @media (max-width: 1024px) {
      display: none;
    }

    .header-link-container {
      position: relative;
      margin-right: 25px;
      padding: 27px 0;

      .header-subnav {
        position: absolute;
        // left: 0;
        left: -16px;
        right: -80px;
        top: 100px;
        padding: 40px 15px 10px 15px;
        background-color: rgba(255, 255, 255, 0.8);
        transition: .3s ease-in-out;
        transition-property: top, opacity, width;
        opacity: 0;
        // width: 20%;

        .header-subnav-inner {
          margin-top: -60px;
        }

        .header-sublink {
          display: block;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;

          color: $colorAccent1;
          margin-bottom: 15px;
          opacity: 0;
          transform: translate(0, -15px);
          transition: .3s ease-in-out;
          transition-delay: .3s;
          white-space: nowrap;
          font-size: 14px;

          &:last-child {
            margin-bottom: 0;
          }

          &:after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 50px;
            height: 3px;
            background-color: #004367;
            opacity: 0;
            transition: opacity .5s;
          }

          &:hover:after,
          &.is-active:after {
            content: '';
            opacity: 1;
          }
        }
      }

      &:hover {
        .header-subnav {
          top: 87px;
          opacity: 1;

          .header-sublink {
            transform: translate(0, 0);
            opacity: 1;
          }
        }
      }
    }



    .header-link {
      position: relative;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 1px;
      font-weight: 500;
      color: $colorAccent1;

      transition: all 0.35s ease;

      &:hover {
        color: $colorAccent1;
      }

      @media (max-width: 1112px) {
        font-size: 14px;
        // margin: 0 10px;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        width: 50px;
        height: 3px;
        transform: translateX(-50%);
        background-color: #004367;
        opacity: 0;
        transition: opacity .5s;
      }

      &:hover:after,
      &.is-active:after {
        content: '';
        opacity: 1;
      }
    }
  }

  .phone-number {
    display: none;

    @media (max-width: 1024px) {
      display: inline-block;
      margin: 0 15px 0 0;
      padding: 0 15px;
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .site-btn {
      @media (max-width: 1400px) {
        padding: 10px 20px;
        font-size: 12px;
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
}
