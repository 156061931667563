.problematicAssociatedListing {
  .matrix-container {
    margin-top: 50px;
  }
}

.problematic-listing{
  padding: calc(125px + 100px) 0 0 0;

  @media(max-width: 767px){
    padding: 125px 0 0 0;
  }

  @media(max-width: 599px){
    padding: 75px 0 0 0;
  }

  .problematic-scope{
    height: 90%;
  }

  .blog001 {
    @media(max-width: 1159px){
      margin-top: 100px;
    }
  }

  .problematic-heading{
    margin: 0 auto 70px;
    color: $colorAccent1;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 35px;
    color: #004367;

    @media(max-width: 767px){
      font-size: 25px;
      margin-bottom: 20px;
    }
  }

  .problematic-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 150px;
    color: $dark;

    transition: height .5s;

    @media(max-width: 767px){
      height: auto !important;
      flex-direction: column;
      margin-bottom: 25px;
    }
  }

  .problematic-container-left{
    position: relative;
    width: calc(100% / 3);
    margin-right: 90px;
    padding: 35px 0;

    @media(max-width: 767px){
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      width: 100%;
    }

    @media(max-width: 420px){
      padding: 20px 0;
      margin-right: 0;
    }

    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: -65px;
      display: block;
      height: 6px;
      width: 130px;
      background-color: $colorAccent1;
      transition-delay: all .5s;

      @media(max-width: 599px){
        width: 75px;
        height: 3px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }

    .problematic-description p{
      margin: 0;
      line-height: 2;
    }

    .problematic-list{
      width: 100%;
      margin-top: 50px;
      padding-left: 0;
      list-style: none;

      @media(max-width: 767px){
        margin-top: 30px;
        margin-bottom: 30px;
      }

      @media(max-width: 420px){
        margin: 10px 0;
      }
    }

    .problematic-list-items{
      width: 100%;
      margin-top: 10px;
      border-bottom: 2px solid #A4A4A4;
      font-weight: 500;

      transition: all .5s;

      @media(max-width: 599px){
        margin: 20px 0;
      }

      &.is-active{
        color: $colorAccent1;
        font-weight: 600;
      }

      &:hover{
        color: $colorAccent1;
        cursor: pointer;
      }
    }
  }

  .problematic-container-right{
    position: relative;
    width: calc((100% / 3) * 2);

    @media(max-width: 767px){
      width: auto;
    }

    .problematic-details{
      position: absolute;
      background-color: $white;
      border-left: 5px solid $colorAccent1;
      box-shadow: 0px 2px 34px rgba(199, 199, 199, 0.5);

      @media(max-width: 767px){
        position: relative;
      }

      &.is-active{
        display: block;
      }
    }
    img{
      width: 100%;
    }

    .problematic-infos{
      padding: 55px 40px 30px;

      @media(max-width: 767px){
        padding: 20px;
      }

      .problematic-heading{
        margin: 0 0 55px;
        max-width: 540px;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;

        @media(max-width: 767px){
          margin: 0 0 10px;
        }
      }

      .problematic-text p {
        margin: 0 0 80px;
        max-width: 630px;
        line-height: 1.5;

        @media(max-width: 767px){
          margin: 0 0 20px;
        }
      }

      .problematic-btn {
        justify-content: flex-start;
      }
    }
  }

}
