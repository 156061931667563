.centre{

  .step001.ol{
    margin-bottom: 0;
    padding: 70px 0;
    min-height: 660px;

    @media(max-width: 767px){
      .steps-list-container{
        margin-top: 100px;
      }
    }
  }

  .block-custom-cta001{
    margin-top: 0;
  }
}
