.popup-alert-btn {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  line-height: 1;
  letter-spacing: -.025em;
  text-align: center;
  cursor: pointer;
  transition: .3s;
  transition-duration: .3s;
  -webkit-appearance: none;
  border-radius: 3px;
}

.popup-alert-btn:hover {
  opacity: .75;
}

/*****
POPUP ALERT BANNER
*****/
.popup-banner-wrapper {
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //padding: 22px 20px;
  //background: #004367;
  //text-align: center;
  //width: 100%;
  display: none;
}

.banner-btn {
  padding: 15px 20px;
  background: transparent;
  border: 1px solid #fff;
}

/*****
POPUP ALERT BOX
*****/
.popup-alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1001 ;
  display: none;
}

.popup-alert-wrapper.is-visible {
  display: block;
}

.popup-alert-wrapper:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;

  display: block;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.8);
  z-index: 1;
}

.popup-alert-box {
  position: absolute;
  top: 50%;
  left: 50%;

  display: block;
  margin: auto;
  width: 80vw;
  max-width: 1020px;

  z-index: 2;
  transform: translate(-50%, -50%);
}

.popup-alert-header {
  display: flex;
  align-items: center;
  padding: 30px;
  background: #004367;
  border-radius: 4px 4px 0 0;
}

.popup-alert-title {
  color: #fff;
  font-size: 26px;
  margin: 0 0 0 20px;
  text-align: center;
}

.popup-alert-content {
  padding: 10px 30px 30px;
  background: #F8F8F8;
  text-align: center;
  border-radius: 0 0 4px 4px;
}

.popup-alert-content p {
  color: #004367;
  font-size: 16px;
  line-height: 160%;
  font-weight: normal;
  text-align: left;
}

.popup-btn {
  background: #004367;
  margin-top: 40px;
}

@media (max-width: 767px) {
  .popup-alert-title {
    font-size: 18px;
  }

  .popup-banner-title {
    font-size: 16px;
    margin: 0 20px;
  }

  .popup-alert-content {
    max-height: 60vh;
    overflow: scroll;
  }

  .banner-btn {
    padding: 15px 10px;
    font-size: 12px;
  }
}
